@import "./player.less";

// @import "./aliyunoss.less";
* {
  margin: 0;
  padding: 0;
  // list-style: none;
  // font-family:
  //   PingFang SC,
  //   PingFang SC;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 16px;
  /* 确保 1rem = 16px */
}

.microfront-root {
  width: 100%;
  height: 100%;
}



.pagination-wrapper {
  width: 100%;
  display: flex;
  justify-content: end;
}

.logi_form_titlerekeli {
  display: flex;
  align-items: center;
}

// ui input样式覆盖
.vl-input {
  .arco-input-inner-wrapper {
    background: white !important;
  }
}

.arco-link:hover {
  background: transparent;
}

.arco-checkbox:hover .arco-checkbox-icon-hover::before {
  background-color: transparent;
}

// ui tab样式覆
.vlong-tab-wrapper {
  .arco-tabs-header-ink {
    height: 4px;
    background-image: linear-gradient(90deg, #ab50ff, #7b61ff);
    border-radius: 4px;
  }

  .arco-tabs-header-nav::before {
    background: transparent;
  }

  .arco-tabs-header-title {
    font-weight: 400;
    font-size: 14px;
    color: #666666;

    &.arco-tabs-header-title-active {
      font-weight: 500;
      font-size: 14px;
      color: #165dff;
    }
  }
}

.arco-checkbox-mask {
  border: 1px solid rgb(229, 230, 235);
}

.vlong-select {
  border-radius: 2px 2px 2px 2px;
  border: 1px solid #dddddd;

  .arco-select-view {
    background: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  .arco-select-view {
    border-color: transparent !important;
  }

  .arco-modal-content {
    padding: 0;
  }
}

.vl-modal {
  .arco-modal-header {
    background: linear-gradient(135deg,
        rgba(65, 122, 255, 0.3) 0%,
        rgba(171, 80, 255, 0.084) 100%);

    .arco-modal-title {
      height: 57px;
      display: flex;
      align-items: center;
    }
  }

  .arco-modal-content {
    padding: 25px;
    padding-top: 15px;
    padding-bottom: 0;
  }
}

.input::-webkit-input-placeholder {
  padding-left: 5px;
}

.vl-input {
  background: white;
  // border: 1px solid #f2f3f5;
}

::-webkit-scrollbar {
  width: 6px;
  /* 设置滚动条的宽度 */
  height: 6px;
  /* 设置水平滚动条的高度 */
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* 滚动条滑块颜色 */
  border-radius: 10px;
  /* 滚动条滑块圆角 */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* 当用户悬停时滑块的颜色 */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* 滚动条轨道颜色 */
  border-radius: 10px;
  /* 滚动条轨道圆角 */
}

// 覆盖arco.design样式
.arco-picker-focused .arco-picker-input-active input,
.arco-picker-focused:hover .arco-picker-input-active input {
  background: transparent;
}

.arco-input-inner-wrapper {
  background: transparent;
  border-radius: 2px;
  border: 1px solid #dddddd;
}

.arco-icon-hover:hover::before {
  background: transparent !important;
}

.arco-btn-primary {
  border: none !important;
}

.arco-picker {
  background: transparent;
  border-radius: 2px;
  border: 1px solid #dddddd;
}

.arco-picker:hover {
  background: transparent;
  border-color: #dddddd;
}

.arco-picker-focused,
.arco-picker-focused:hover {
  background: transparent;
  border-color: rgb(22, 93, 255) !important;
}

.arco-input-inner-wrapper:hover {
  background: transparent;
  border-color: #dddddd;
}

.arco-input-inner-wrapper.arco-input-inner-wrapper-focus {
  border-color: rgb(22, 93, 255);
}

.arco-select-popup {
  padding: 0;
}

.arco-input {
  background: transparent;
  border-radius: 2px;
  border: 1px solid #dddddd;
}

.arco-input:hover {
  border-color: #dddddd;
  background: transparent;
}

.arco-input:focus {
  border-color: rgb(22, 93, 255) !important;
}

.arco-select .arco-select-view {
  background: transparent;
  border-radius: 2px;
  border: 1px solid #dddddd;
}

.arco-select:hover .arco-select-view {
  border-color: #dddddd;
  background: transparent;
}

.arco-select.arco-select-focused .arco-select-view {
  border-color: rgb(22, 93, 255) !important;
}

.arco-tabs-header-nav-line .arco-tabs-header-title:hover .arco-tabs-header-title-text::before {
  background-color: white;
}

.arco-btn-text:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
  background-color: white;
}

.arco-modal-footer {
  border: none;
}

.arco-modal-header .arco-modal-title {
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}

.arco-btn {
  line-height: normal;
}

.modals-content {
  padding: 25px;
  padding-bottom: 0;
}

.arco-message {
  border-radius: 4px !important;
}

.arco-form-item-status-error .arco-input:not(.arco-input-disabled),
.arco-form-item-status-error .arco-input-inner-wrapper:not(.arco-input-inner-wrapper-disabled),
.arco-form-item-status-error .arco-textarea:not(.arco-textarea-disabled) {
  border-color: red;
  background-color: initial;
}

.arco-form-item-status-error .arco-input:not(.arco-input-disabled):hover,
.arco-form-item-status-error .arco-input-inner-wrapper:not(.arco-input-inner-wrapper-disabled):hover,
.arco-form-item-status-error .arco-textarea:not(.arco-textarea-disabled):hover {
  border-color: red;
  background-color: initial;
}

._children-wrapper {
  width: 100%;
}

