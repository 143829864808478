@charset "UTF-8";
.ConfigWrapper_player_confirmModal__1KJj6 .ant-modal-content {
  background-color: #03060b;
  color: #fff;
  box-shadow: 0 0 4px 1px #ffffff40;
}

.ConfigWrapper_player_confirmModal__1KJj6
  .ant-modal-confirm-body
  .ant-modal-confirm-content,
.ConfigWrapper_player_confirmModal__1KJj6
  .ant-modal-confirm-body
  .ant-modal-confirm-title {
  color: #fff;
}

.ConfigWrapper_player_confirmModal__1KJj6 .ant-modal-confirm-btns .ant-btn {
  color: #fff9;
  border-color: #ffffff1f;
  background-color: #ffffff1f;
  outline: none;
}

.ConfigWrapper_player_confirmModal__1KJj6
  .ant-modal-confirm-btns
  .ant-btn:hover {
  color: #fff;
  background-color: #fff3;
}

.ConfigWrapper_player_confirmModal__1KJj6
  .ant-modal-confirm-btns
  .ant-btn-primary {
  color: #fff;
  background-color: #1378f9;
}

.ConfigWrapper_player_confirmModal__1KJj6
  .ant-modal-confirm-btns
  .ant-btn-primary:hover {
  background-color: #1267d3;
}

@keyframes ConfigWrapper_player_loading__3TtGJ {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ConfigWrapper_player_config_wrapper__2FvIg .basic-select,
.ConfigWrapper_player_config_wrapper__2FvIg .basic-textarea,
.ConfigWrapper_player_config_wrapper__2FvIg .basic-input {
  position: relative;
  padding: 4px 8px;
  color: #fffc;
  background-color: #ffffff1a;
  border: none;
  border-radius: 4px;
}

.ConfigWrapper_player_config_wrapper__2FvIg {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff14;
}

.ConfigWrapper_player_config_wrapper__2FvIg .ConfigWrapper_player_tabs__3YYjj {
  height: 44px;
  background-color: #ffffff0f;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 0;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_tabs__3YYjj
  .ConfigWrapper_player_tab__2aEsL {
  position: relative;
  padding: 0 12px;
  cursor: pointer;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_tabs__3YYjj
  .ConfigWrapper_player_tab__2aEsL.ConfigWrapper_player_active__3vgYJ {
  color: #fff;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_tabs__3YYjj
  .ConfigWrapper_player_tab__2aEsL:not(:last-child):after {
  display: block;
  position: absolute;
  content: "";
  height: 8px;
  width: 1px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.18);
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_sub_tab_wrapper__g3Ab9 {
  display: flex;
  height: 20px;
  line-height: 20px;
  color: #c1c1c1;
  margin-bottom: 16px;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_sub_tab_wrapper__g3Ab9
  .ConfigWrapper_player_sub_tab__3ESRR {
  margin-right: 10px;
  padding: 0 6px;
  cursor: pointer;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_sub_tab_wrapper__g3Ab9
  .ConfigWrapper_player_sub_tab_active__UUNEr {
  color: #fff;
  background-color: #1378f9;
  border-radius: 10px;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_control_area__1paGV {
  position: relative;
  padding: 12px 12px 0;
  flex-grow: 1;
  font-size: 12px;
  overflow: auto;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_control_area__1paGV::-webkit-scrollbar-track-piece {
  background: transparent;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_control_area__1paGV::-webkit-scrollbar-corner {
  background: transparent;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_control_area__1paGV::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_control_area__1paGV::-webkit-scrollbar-thumb {
  background-color: #3c3e42;
  border-radius: 5px;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_control_area__1paGV
  .ConfigWrapper_player_inline_row__Lfm7i {
  display: flex;
  align-items: center;
  min-width: 280px;
  margin-bottom: 16px;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_control_area__1paGV
  .ConfigWrapper_player_inline_row__Lfm7i
  .ConfigWrapper_player_label__3scg3,
.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_control_area__1paGV
  .ConfigWrapper_player_inline_row__Lfm7i
  .ConfigWrapper_player_label_en___OdH9 {
  flex-shrink: 0;
  margin-bottom: 0;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_control_area__1paGV
  .ConfigWrapper_player_inline_row__Lfm7i
  .ConfigWrapper_player_label__3scg3 {
  width: 55px;
  margin-right: 5px;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_control_area__1paGV
  .ConfigWrapper_player_inline_row__Lfm7i
  .ConfigWrapper_player_label_en___OdH9 {
  width: 75px;
  margin-right: 5px;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_control_area__1paGV
  .ConfigWrapper_player_inline_row__Lfm7i
  .ConfigWrapper_player_controller__32C59:not(:empty) {
  flex-grow: 1;
  margin-bottom: 0;
  max-width: 495px;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_control_area__1paGV
  .ConfigWrapper_player_label__3scg3 {
  color: #fffc;
  margin-bottom: 12px;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_control_area__1paGV
  .ConfigWrapper_player_controller__32C59:not(:empty) {
  position: relative;
  margin-bottom: 16px;
  min-width: 250px;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .ConfigWrapper_player_control_area__1paGV
  .ConfigWrapper_player_default_text__MJdSn {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ConfigWrapper_player_config_wrapper__2FvIg .basic-input {
  height: 20px;
}

.ConfigWrapper_player_config_wrapper__2FvIg .basic-select {
  height: 20px;
  appearance: none;
  padding: 0 24px 0 8px;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='rgba(255, 255, 255, 0.8)'%0A%3E%3Cpath d='M512 610.88L930.88 192l93.12 93.12-512 512-512-512L93.12 192z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: calc(100% - 6px);
}

.ConfigWrapper_player_config_wrapper__2FvIg .basic-select option {
  background-color: #313438;
}

.ConfigWrapper_player_config_wrapper__2FvIg .basic-checkboxes {
  display: flex;
  align-items: center;
  min-width: 260px;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .basic-checkboxes
  input[type="checkbox"] {
  appearance: none;
  width: 16px;
  height: 16px;
  margin: 0;
  line-height: 16px;
  text-align: center;
  background-color: #ffffff1a;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .basic-checkboxes
  input[type="checkbox"]:checked {
  background-color: #1378f9;
  color: #fff;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .basic-checkboxes
  input[type="checkbox"]:checked:after {
  content: "✓";
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .basic-checkboxes
  input[type="checkbox"]:disabled {
  color: #fff;
  cursor: not-allowed;
}

.ConfigWrapper_player_config_wrapper__2FvIg
  .basic-checkboxes
  input[type="checkbox"]:disabled:after {
  content: "-";
}

.ConfigWrapper_player_config_wrapper__2FvIg .basic-checkboxes label {
  padding-left: 8px;
  margin-right: 30px;
  color: #fffc;
}

.ConfigWrapper_player_timeInputWrap__1y1V5 {
  width: 104px;
}

.ConfigWrapper_player_timeInputWrap__1y1V5 input {
  flex: auto;
}

.AntdSlider_player_antd_slider_container__i_Kkx {
  height: 100%;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  height: 14px;
  margin: 4px 8px;
  padding: 3px 0;
  cursor: pointer;
  -ms-touch-action: none;
  touch-action: none;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-vertical
  .ant-slider-rail {
  width: 4px;
  height: 100%;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-vertical
  .ant-slider-track {
  width: 4px;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-vertical
  .ant-slider-handle {
  margin-top: -6px;
  margin-left: -5px;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-vertical
  .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-vertical
  .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-vertical
  .ant-slider-step {
  width: 4px;
  height: 100%;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-vertical
  .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-tooltip
  .ant-tooltip-inner {
  min-width: unset;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-rtl.ant-slider-vertical
  .ant-slider-handle {
  margin-right: -5px;
  margin-left: 0;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-rtl.ant-slider-vertical
  .ant-slider-mark {
  right: 12px;
  left: auto;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-rtl.ant-slider-vertical
  .ant-slider-mark-text {
  right: 4px;
  left: auto;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-rtl.ant-slider-vertical
  .ant-slider-dot {
  right: 2px;
  left: auto;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider-with-marks {
  margin-bottom: 28px;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 8px;
  background-color: #383838;
  border-radius: 8px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider-track {
  position: absolute;
  height: 8px;
  background-color: #515151;
  border-radius: 8px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -3px;
  background-color: #c4c4c4;
  border-radius: 50%;
  border: none;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  cursor: pointer;
  -webkit-transition:
    border-color 0.3s,
    -webkit-box-shadow 0.6s,
    -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition:
    border-color 0.3s,
    -webkit-box-shadow 0.6s,
    -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition:
    border-color 0.3s,
    box-shadow 0.6s,
    transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition:
    border-color 0.3s,
    box-shadow 0.6s,
    transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28),
    -webkit-box-shadow 0.6s,
    -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
  border-color: #46a6ff;
  -webkit-box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
  box-shadow: 0 0 0 5px #1890ff1f;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider-handle:focus {
  border-color: #46a6ff;
  outline: none;
  -webkit-box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
  box-shadow: 0 0 0 5px #1890ff1f;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-handle.ant-tooltip-open {
  border-color: #1890ff;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider:hover
  .ant-slider-rail {
  background-color: #383838;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider:hover
  .ant-slider-track {
  background-color: #515151;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: #00000073;
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider-mark-text-active {
  color: #000000d9;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider-step {
  position: absolute;
  width: 100%;
  height: 8px;
  background: transparent;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  cursor: pointer;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider-dot:first-child {
  margin-left: -4px;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider-dot:last-child {
  margin-left: -4px;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider-dot-active {
  border-color: #8cc8ff;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider-disabled {
  cursor: not-allowed;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-disabled
  .ant-slider-track {
  background-color: #00000040 !important;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-disabled
  .ant-slider-handle,
.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-disabled
  .ant-slider-dot {
  background-color: #fff;
  border-color: #00000040 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-disabled
  .ant-slider-mark-text,
.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-disabled
  .ant-slider-dot {
  cursor: not-allowed !important;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider-rtl {
  direction: rtl;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-rtl
  .ant-slider-mark {
  right: 0;
  left: auto;
}

.AntdSlider_player_antd_slider_container__i_Kkx .ant-slider-rtl .ant-slider-dot,
.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-rtl
  .ant-slider-dot:first-child {
  margin-right: -4px;
  margin-left: 0;
}

.AntdSlider_player_antd_slider_container__i_Kkx
  .ant-slider-rtl
  .ant-slider-dot:last-child {
  margin-right: -4px;
  margin-left: 0;
}

.AntdSliderWithInput_player_confirmModal__1_hJ8 .ant-modal-content {
  background-color: #03060b;
  color: #fff;
  box-shadow: 0 0 4px 1px #ffffff40;
}

.AntdSliderWithInput_player_confirmModal__1_hJ8
  .ant-modal-confirm-body
  .ant-modal-confirm-content,
.AntdSliderWithInput_player_confirmModal__1_hJ8
  .ant-modal-confirm-body
  .ant-modal-confirm-title {
  color: #fff;
}

.AntdSliderWithInput_player_confirmModal__1_hJ8
  .ant-modal-confirm-btns
  .ant-btn {
  color: #fff9;
  border-color: #ffffff1f;
  background-color: #ffffff1f;
  outline: none;
}

.AntdSliderWithInput_player_confirmModal__1_hJ8
  .ant-modal-confirm-btns
  .ant-btn:hover {
  color: #fff;
  background-color: #fff3;
}

.AntdSliderWithInput_player_confirmModal__1_hJ8
  .ant-modal-confirm-btns
  .ant-btn-primary {
  color: #fff;
  background-color: #1378f9;
}

.AntdSliderWithInput_player_confirmModal__1_hJ8
  .ant-modal-confirm-btns
  .ant-btn-primary:hover {
  background-color: #1267d3;
}

@keyframes AntdSliderWithInput_player_loading__2sLFc {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.AntdSliderWithInput_player_slider_with_input__heHS9 {
  display: flex;
  align-items: center;
}

.AntdSliderWithInput_player_slider_with_input__heHS9
  .AntdSliderWithInput_player_slider__2ng1G {
  flex: auto;
  max-width: 380px;
  min-width: 120px;
  display: inline-block;
}

.AntdSliderWithInput_player_slider_with_input__heHS9
  .AntdSliderWithInput_player_input_number__uZRqm {
  margin-left: 16px;
}

.AntdSliderWithInput_player_slider_with_input__heHS9
  .AntdSliderWithInput_player_unit__2_fZQ {
  margin-left: 4px;
}

.AntdSliderWithInput_player_slider_with_input__heHS9
  .AntdSliderWithInput_player_min__Bwpsn,
.AntdSliderWithInput_player_slider_with_input__heHS9
  .AntdSliderWithInput_player_max__27Uiz {
  text-align: center;
}

.AntdSliderWithInput_player_slider_with_input__heHS9 .ant-slider-rail {
  background-color: #777;
}

.AntdSliderWithInput_player_slider_with_input__heHS9 .ant-slider-track {
  background-color: #1378f9;
}

.AntdSliderWithInput_player_slider_with_input__heHS9
  .ant-slider:hover
  .ant-slider-rail {
  background-color: #777;
}

.AntdSliderWithInput_player_slider_with_input__heHS9
  .ant-slider:hover
  .ant-slider-track {
  background-color: #1378f9;
}

.AntdSliderWithInput_player_input_number__uZRqm {
  display: flex;
  height: 20px;
  background-color: #ffffff1a;
  border-radius: 4px;
}

.AntdSliderWithInput_player_input_number__uZRqm input {
  width: 50px;
  padding: 4px 4px 4px 8px;
  border: none;
  background: transparent;
  color: #fff;
}

.AntdSliderWithInput_player_input_number__uZRqm
  .AntdSliderWithInput_player_controllers__3tDMX {
  display: flex;
  width: 14px;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  flex-direction: column;
}

.AntdSliderWithInput_player_input_number__uZRqm
  .AntdSliderWithInput_player_controllers__3tDMX
  .AntdSliderWithInput_player_increase__i2KUo,
.AntdSliderWithInput_player_input_number__uZRqm
  .AntdSliderWithInput_player_controllers__3tDMX
  .AntdSliderWithInput_player_decrease__2TMUl {
  display: flex;
  height: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.AntdSliderWithInput_player_input_number__uZRqm
  .AntdSliderWithInput_player_controllers__3tDMX
  .AntdSliderWithInput_player_increase__i2KUo
  i,
.AntdSliderWithInput_player_input_number__uZRqm
  .AntdSliderWithInput_player_controllers__3tDMX
  .AntdSliderWithInput_player_decrease__2TMUl
  i {
  display: block;
  transform: scale(0.32);
}

.AntdSliderWithInput_player_input_number__uZRqm
  .AntdSliderWithInput_player_controllers__3tDMX
  .AntdSliderWithInput_player_increase__i2KUo.disabled,
.AntdSliderWithInput_player_input_number__uZRqm
  .AntdSliderWithInput_player_controllers__3tDMX
  .AntdSliderWithInput_player_decrease__2TMUl.disabled {
  pointer-events: none;
}

.AntdSliderWithInput_player_input_number__uZRqm
  .AntdSliderWithInput_player_controllers__3tDMX:before {
  content: "";
  display: block;
  width: 1px;
  height: 10px;
  background-color: #373737;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.Icons_player_iconfont__3bVf5 {
  font-family: websdk-iconfont;
  font-style: normal;
  font-size: 20px;
}

.TextReminder_player_confirmModal__1ww2U .ant-modal-content {
  background-color: #03060b;
  color: #fff;
  box-shadow: 0 0 4px 1px #ffffff40;
}

.TextReminder_player_confirmModal__1ww2U
  .ant-modal-confirm-body
  .ant-modal-confirm-content,
.TextReminder_player_confirmModal__1ww2U
  .ant-modal-confirm-body
  .ant-modal-confirm-title {
  color: #fff;
}

.TextReminder_player_confirmModal__1ww2U .ant-modal-confirm-btns .ant-btn {
  color: #fff9;
  border-color: #ffffff1f;
  background-color: #ffffff1f;
  outline: none;
}

.TextReminder_player_confirmModal__1ww2U
  .ant-modal-confirm-btns
  .ant-btn:hover {
  color: #fff;
  background-color: #fff3;
}

.TextReminder_player_confirmModal__1ww2U
  .ant-modal-confirm-btns
  .ant-btn-primary {
  color: #fff;
  background-color: #1378f9;
}

.TextReminder_player_confirmModal__1ww2U
  .ant-modal-confirm-btns
  .ant-btn-primary:hover {
  background-color: #1267d3;
}

@keyframes TextReminder_player_loading__sp_AK {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.TextReminder_player_text_reminder__2Io4B {
  padding: 0;
}

.TextReminder_player_text_reminder__2Io4B .ant-tooltip-arrow {
  display: none;
}

.TextReminder_player_text_reminder__2Io4B .ant-tooltip-inner {
  min-height: 24px;
  padding: 0 8px;
  background-color: #333538;
  line-height: 24px;
  font-size: 12px;
  color: #dedede;
}

.TransitionConfig_player_name__12B0c {
  font-weight: 700;
}

.TimeStepper_player_confirmModal__1oTIS .ant-modal-content {
  background-color: #03060b;
  color: #fff;
  box-shadow: 0 0 4px 1px #ffffff40;
}

.TimeStepper_player_confirmModal__1oTIS
  .ant-modal-confirm-body
  .ant-modal-confirm-content,
.TimeStepper_player_confirmModal__1oTIS
  .ant-modal-confirm-body
  .ant-modal-confirm-title {
  color: #fff;
}

.TimeStepper_player_confirmModal__1oTIS .ant-modal-confirm-btns .ant-btn {
  color: #fff9;
  border-color: #ffffff1f;
  background-color: #ffffff1f;
  outline: none;
}

.TimeStepper_player_confirmModal__1oTIS .ant-modal-confirm-btns .ant-btn:hover {
  color: #fff;
  background-color: #fff3;
}

.TimeStepper_player_confirmModal__1oTIS
  .ant-modal-confirm-btns
  .ant-btn-primary {
  color: #fff;
  background-color: #1378f9;
}

.TimeStepper_player_confirmModal__1oTIS
  .ant-modal-confirm-btns
  .ant-btn-primary:hover {
  background-color: #1267d3;
}

@keyframes TimeStepper_player_loading__QVb0u {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.TimeStepper_player_timeStepper__zz3HP {
  display: inline-flex;
  height: 20px;
  background-color: #ffffff1a;
  border-radius: 4px;
  overflow: hidden;
  padding-left: 4px;
}

.TimeStepper_player_timeStepper__zz3HP .TimeStepper_player_controllers__3BOFF {
  height: 100%;
  width: 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.TimeStepper_player_timeStepper__zz3HP
  .TimeStepper_player_controllers__3BOFF
  .TimeStepper_player_increase__1DgZP,
.TimeStepper_player_timeStepper__zz3HP
  .TimeStepper_player_controllers__3BOFF
  .TimeStepper_player_decrease__2cD8A {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
}

.TimeStepper_player_timeStepper__zz3HP
  .TimeStepper_player_controllers__3BOFF
  .TimeStepper_player_increase__1DgZP
  i,
.TimeStepper_player_timeStepper__zz3HP
  .TimeStepper_player_controllers__3BOFF
  .TimeStepper_player_decrease__2cD8A
  i {
  transform: scale(0.6);
  font-size: 12px;
  line-height: 10px;
}

.TimeStepper_player_timeStepper__zz3HP
  .TimeStepper_player_controllers__3BOFF
  .TimeStepper_player_increase__1DgZP
  i.disabled,
.TimeStepper_player_timeStepper__zz3HP
  .TimeStepper_player_controllers__3BOFF
  .TimeStepper_player_decrease__2cD8A
  i.disabled {
  color: #ffffff4d;
}

.TimeStepper_player_timeStepperItem__3WB9O {
  background-color: transparent;
  color: #888;
  border: none;
  padding: 0;
  text-align: center;
  line-height: 20px;
}

.TimeStepper_player_timeStepperItem__3WB9O::selection {
  background-color: #d8d8d8;
  color: #519de9;
}

.ClipMarks_player_confirmModal__19SdB .ant-modal-content {
  background-color: #03060b;
  color: #fff;
  box-shadow: 0 0 4px 1px #ffffff40;
}

.ClipMarks_player_confirmModal__19SdB
  .ant-modal-confirm-body
  .ant-modal-confirm-content,
.ClipMarks_player_confirmModal__19SdB
  .ant-modal-confirm-body
  .ant-modal-confirm-title {
  color: #fff;
}

.ClipMarks_player_confirmModal__19SdB .ant-modal-confirm-btns .ant-btn {
  color: #fff9;
  border-color: #ffffff1f;
  background-color: #ffffff1f;
  outline: none;
}

.ClipMarks_player_confirmModal__19SdB .ant-modal-confirm-btns .ant-btn:hover {
  color: #fff;
  background-color: #fff3;
}

.ClipMarks_player_confirmModal__19SdB .ant-modal-confirm-btns .ant-btn-primary {
  color: #fff;
  background-color: #1378f9;
}

.ClipMarks_player_confirmModal__19SdB
  .ant-modal-confirm-btns
  .ant-btn-primary:hover {
  background-color: #1267d3;
}

@keyframes ClipMarks_player_loading__E39g- {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ClipMarks_player_clipMarks__2rg-K .ClipMarks_player_markWrapper__1bFNM {
  position: absolute;
  top: 0;
  opacity: 0.6;
}

.ClipMarks_player_clipMarks__2rg-K
  .ClipMarks_player_markWrapper__1bFNM.ClipMarks_player_highlight__Xqi18 {
  opacity: 1;
}

.ClipMarks_player_clipMarks__2rg-K
  .ClipMarks_player_markWrapper__1bFNM
  .ClipMarks_player_markPoint__1EkaP {
  width: 24px;
  height: 40px;
}

.ClipMarks_player_clipMarks__2rg-K
  .ClipMarks_player_markWrapper__1bFNM
  .ClipMarks_player_markFragment__2vqRz {
  background-color: #1378f9;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 0 8px 2px #0009;
  height: 12px;
}

.ClipMarks_player_popover__2kGOK {
  position: fixed;
  width: 408px;
  background-color: #141415;
  border: 1px solid rgba(255, 255, 255, 0.08);
  box-shadow: 0 1px 4px #00000021;
  padding: 8px 16px;
  z-index: 2;
}

.ClipMarks_player_popover__2kGOK .ClipMarks_player_closeButton__18hm1 {
  position: absolute;
  right: 16px;
  top: 8px;
  font-size: 12px;
}

.ClipMarks_player_popover__2kGOK .ClipMarks_player_title__Vle0k {
  margin-bottom: 8px;
}

.ClipMarks_player_popover__2kGOK
  .ClipMarks_player_title__Vle0k
  .ClipMarks_player_text__qEEKz {
  color: #ffffffb8;
}

.ClipMarks_player_popover__2kGOK
  .ClipMarks_player_title__Vle0k
  .ClipMarks_player_button__2Ei43 {
  color: #1378f9;
  opacity: 0.6;
  margin-left: 8px;
  cursor: pointer;
}

.ClipMarks_player_popover__2kGOK
  .ClipMarks_player_title__Vle0k
  .ClipMarks_player_button__2Ei43:hover {
  opacity: 1;
}

.ClipMarks_player_content__2lMuu {
  color: #ffffff4d;
}

.ClipMarks_player_content__2lMuu select {
  background-color: #ffffff1a;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 0 20px 0 10px;
  cursor: pointer;
}

.ClipMarks_player_content__2lMuu select option {
  background-color: #383b40;
  color: #fffc;
}

.ClipMarks_player_content__2lMuu textarea {
  background-color: #222;
  color: #fff;
  border: none;
  resize: none;
  width: 346px;
  padding: 4px 5px;
}

.ClipMarks_player_content__2lMuu .ClipMarks_player_jumpButton__1XEXF {
  font-size: 14px;
  color: #fff;
  opacity: 0.4;
  margin-left: 6px;
}

.ClipMarks_player_content__2lMuu .ClipMarks_player_jumpButton__1XEXF:hover {
  opacity: 1;
}

.ClipMarks_player_content__2lMuu .ClipMarks_player_materialName__10wx5 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ClipMarks_player_content__2lMuu .ClipMarks_player_okButton__3Z04v {
  display: inline-block;
  width: 40px;
  height: 24px;
  line-height: 24px;
  opacity: 0.4;
  background-color: #0070cc;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.ClipMarks_player_content__2lMuu .ClipMarks_player_okButton__3Z04v:hover {
  opacity: 1;
}

.ClipMarks_player_content__2lMuu .ClipMarks_player_cancelButton__2zCk5 {
  margin-left: 8px;
  display: inline-block;
  width: 40px;
  height: 24px;
  line-height: 24px;
  opacity: 0.4;
  border: 1px solid white;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.ClipMarks_player_content__2lMuu .ClipMarks_player_cancelButton__2zCk5:hover {
  opacity: 1;
}

.ClipMarks_player_content__2lMuu .ClipMarks_player_editButton__2MwOf {
  margin-left: 8px;
  color: #0070cc;
  opacity: 0.6;
  cursor: pointer;
}

.ClipMarks_player_content__2lMuu .ClipMarks_player_editButton__2MwOf:hover {
  opacity: 1;
}

.ClipMarks_player_content__2lMuu .ClipMarks_player_editButton__2MwOf i {
  color: #0070cc;
  cursor: pointer;
  font-size: 14px;
}

.ClipMarks_player_content__2lMuu .ClipMarks_player_bottomButtons__2nxtZ {
  margin-top: 8px;
}

.ClipMarks_player_content__2lMuu
  .ClipMarks_player_bottomButtons__2nxtZ
  .ClipMarks_player_button__2Ei43 {
  display: inline-block;
  color: #fff6;
  cursor: pointer;
  position: relative;
  padding-right: 8px;
  margin-right: 8px;
}

.ClipMarks_player_content__2lMuu
  .ClipMarks_player_bottomButtons__2nxtZ
  .ClipMarks_player_button__2Ei43:hover,
.ClipMarks_player_content__2lMuu
  .ClipMarks_player_bottomButtons__2nxtZ
  .ClipMarks_player_button__2Ei43:hover
  i {
  color: #ffffffb8;
}

.ClipMarks_player_content__2lMuu
  .ClipMarks_player_bottomButtons__2nxtZ
  .ClipMarks_player_button__2Ei43
  i {
  cursor: pointer;
  font-size: 14px;
}

.ClipMarks_player_content__2lMuu
  .ClipMarks_player_bottomButtons__2nxtZ
  .ClipMarks_player_button__2Ei43:not(:last-child):after {
  display: block;
  position: absolute;
  content: "";
  height: 8px;
  width: 1px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.18);
}

.ClipMarks_player_tempMarkStart__mMkuZ,
.ClipMarks_player_tempMarkEnd__149oO {
  position: absolute;
  top: 0;
  width: 8px;
  height: 14px;
  border-top: 2px solid #1378f9;
  animation: ClipMarks_player_blink__11sTU 1s step-start infinite;
}

.ClipMarks_player_tempMarkStart__mMkuZ {
  border-left: 2px solid #1378f9;
  transform: translate(2px);
}

.ClipMarks_player_tempMarkEnd__149oO {
  border-right: 2px solid #1378f9;
  transform: translate(-100%);
}

@keyframes ClipMarks_player_blink__11sTU {
  50% {
    opacity: 0;
  }
}

.Player_player_confirmModal__1A9Ko .ant-modal-content {
  background-color: #03060b;
  color: #fff;
  box-shadow: 0 0 4px 1px #ffffff40;
}

.Player_player_confirmModal__1A9Ko
  .ant-modal-confirm-body
  .ant-modal-confirm-content,
.Player_player_confirmModal__1A9Ko
  .ant-modal-confirm-body
  .ant-modal-confirm-title {
  color: #fff;
}

.Player_player_confirmModal__1A9Ko .ant-modal-confirm-btns .ant-btn {
  color: #fff9;
  border-color: #ffffff1f;
  background-color: #ffffff1f;
  outline: none;
}

.Player_player_confirmModal__1A9Ko .ant-modal-confirm-btns .ant-btn:hover {
  color: #fff;
  background-color: #fff3;
}

.Player_player_confirmModal__1A9Ko .ant-modal-confirm-btns .ant-btn-primary {
  color: #fff;
  background-color: #1378f9;
}

.Player_player_confirmModal__1A9Ko
  .ant-modal-confirm-btns
  .ant-btn-primary:hover {
  background-color: #1267d3;
}

@keyframes Player_player_loading__3OBBH {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Player_player_player__13kf6 {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  background-color: #ffffff14;
  position: relative;
}

.Player_player_player__13kf6 canvas {
  vertical-align: middle;
}

.Player_player_player__13kf6 .Player_player_monitorWrap__2K6E8 {
  display: flex;
  flex: 1 1 auto;
  margin: auto;
  background: #16181c;
  width: 100%;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  //   min-width: auto !important;
}

.Player_player_player__13kf6
  .Player_player_monitorWrap__2K6E8::-webkit-scrollbar {
  display: none;
}

.Player_player_player__13kf6 .Player_player_monitor__2c8T7 {
  transform-origin: top left;
  background: #16181c;
  height: 100%;
  width: 100%;
}

.Player_player_player__13kf6 .Player_player_stage__1Axfu {
  position: relative;
  background-color: #16181c;
  width: 100%;
  height: 100%;
}

.Player_player_player__13kf6 .Player_player_player_tool_bar__2nLR3 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.Player_player_player__13kf6
  .Player_player_player_tool_bar__2nLR3
  .Player_player_left__3Vplz {
  position: absolute;
  left: 16px;
  font-family: monospace;
  font-size: 12px;
}

.Player_player_player__13kf6
  .Player_player_player_tool_bar__2nLR3
  .Player_player_middle__dktxO {
  display: flex;
  justify-content: center;
}

.Player_player_player__13kf6
  .Player_player_player_tool_bar__2nLR3
  .Player_player_middle__dktxO
  > div {
  font-size: 30px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.Player_player_player__13kf6
  .Player_player_player_tool_bar__2nLR3
  .Player_player_right__2-1B0 {
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  color: #ffffff80;
}

.Player_player_player__13kf6
  .Player_player_player_tool_bar__2nLR3
  .Player_player_right__2-1B0
  .Player_player_tooltip__Iup4z {
  margin-right: 10px;
}

.Player_player_player__13kf6
  .Player_player_player_tool_bar__2nLR3
  .Player_player_right__2-1B0
  .Player_player_tooltip__Iup4z
  .Player_player_button__AvxGC.Player_player_active__JV5Uh,
.Player_player_player__13kf6
  .Player_player_player_tool_bar__2nLR3
  .Player_player_right__2-1B0
  .Player_player_tooltip__Iup4z
  .Player_player_button__AvxGC:hover {
  background: rgba(85, 85, 85, 0.8);
  color: #ffffffbf;
}

.Player_player_player__13kf6
  .Player_player_player_tool_bar__2nLR3
  .Player_player_right__2-1B0
  .Player_player_speed__223dc,
.Player_player_player__13kf6
  .Player_player_player_tool_bar__2nLR3
  .Player_player_right__2-1B0
  .Player_player_proportion__2FxLG {
  height: 24px;
  line-height: 24px;
  margin: 6px 0;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
}

.Player_player_player__13kf6
  .Player_player_player_tool_bar__2nLR3
  .Player_player_right__2-1B0
  .Player_player_speed__223dc:hover,
.Player_player_player__13kf6
  .Player_player_player_tool_bar__2nLR3
  .Player_player_right__2-1B0
  .Player_player_proportion__2FxLG:hover {
  color: #ffffffb3;
  border: 1px solid rgba(255, 255, 255, 0.7);
}

.Player_player_player__13kf6
  .Player_player_player_tool_bar__2nLR3
  .Player_player_right__2-1B0
  .Player_player_speed__223dc.Player_player_no_border__ZdWSg,
.Player_player_player__13kf6
  .Player_player_player_tool_bar__2nLR3
  .Player_player_right__2-1B0
  .Player_player_proportion__2FxLG.Player_player_no_border__ZdWSg {
  border: none;
  width: auto;
  padding: 0 8px;
  box-sizing: border-box;
}

.Player_player_player__13kf6
  .Player_player_player_tool_bar__2nLR3
  .Player_player_right__2-1B0
  .Player_player_speed__223dc.Player_player_no_border__ZdWSg:hover,
.Player_player_player__13kf6
  .Player_player_player_tool_bar__2nLR3
  .Player_player_right__2-1B0
  .Player_player_proportion__2FxLG.Player_player_no_border__ZdWSg:hover {
  border: none;
}

.Player_player_player__13kf6
  .Player_player_player_tool_bar__2nLR3
  .Player_player_right__2-1B0
  .Player_player_speed__223dc {
  width: 48px;
}

.Player_player_player__13kf6
  .Player_player_player_tool_bar__2nLR3
  .Player_player_right__2-1B0
  .Player_player_proportion__2FxLG {
  min-width: 70px;
  max-width: 108px;
}

.Player_player_player__13kf6 .Player_player_actionBtn__21u0W {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Player_player_player__13kf6
  .Player_player_actionBtn__21u0W
  .Player_player_active__JV5Uh {
  color: #fff;
}

.Player_player_player__13kf6 .Player_player_loading__3OBBH {
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  inset: 0;
}

.Player_player_player__13kf6 .Player_player_error__3s6ci {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 100px);
  font-size: 20px;
  width: 200px;
  text-align: center;
}

.Player_player_player__13kf6 .Player_player_loading_font__VmxA9 {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 32px);
  font-size: 64px;
  animation: Player_player_loading__3OBBH 0.8s linear 0s infinite;
  color: #1378f9;
}

.Player_player_player__13kf6 .Player_player_loading_text__3Xcqs {
  top: calc(50% + 64px);
  left: calc(50% - 32px);
  color: #1378f9;
  position: absolute;
  font-size: 16px;
}

.Player_player_button__AvxGC {
  border: none;
  cursor: pointer;
  height: 26px;
  width: 100%;
  text-align: center;
  line-height: 26px;
}

.Player_player_confirmModalInfoIcon__1pKIU {
  color: #0070cc !important;
}

@media screen and (min-height: 900px) and (max-height: 940px) {
  .Player_player_player__13kf6
    .Player_player_player_tool_bar__2nLR3
    .Player_player_middle__dktxO
    > div {
    margin-left: 6px;
    margin-right: 6px;
  }
}

@media screen and (max-height: 900px) {
  .Player_player_player__13kf6
    .Player_player_player_tool_bar__2nLR3
    .Player_player_middle__dktxO
    > div {
    margin-left: 2px;
    margin-right: 2px;
  }

  .Player_player_player__13kf6
    .Player_player_player_tool_bar__2nLR3
    .Player_player_right__2-1B0 {
    right: 6px;
  }

  .Player_player_player__13kf6
    .Player_player_player_tool_bar__2nLR3
    .Player_player_right__2-1B0
    .Player_player_speed__223dc.Player_player_no_border__ZdWSg,
  .Player_player_player__13kf6
    .Player_player_player_tool_bar__2nLR3
    .Player_player_right__2-1B0
    .Player_player_proportion__2FxLG.Player_player_no_border__ZdWSg {
    padding: 0 6px;
  }

  .Player_player_player__13kf6 .Player_player_audioPeak__26FkA {
    width: 32px;
  }
}

.EmptyTips_player_emptyTips__200Sf {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.ToolbarTime_player_time__2aW-O {
  color: #ffffff80;
  font-family: tahoma, arial, sans-serif;
}

.ToolbarTime_player_current__1vkpF {
  color: #fff;
  display: inline-block;
}

.CropTool_player_confirmModal__181J3 .ant-modal-content {
  background-color: #03060b;
  color: #fff;
  box-shadow: 0 0 4px 1px #ffffff40;
}

.CropTool_player_confirmModal__181J3
  .ant-modal-confirm-body
  .ant-modal-confirm-content,
.CropTool_player_confirmModal__181J3
  .ant-modal-confirm-body
  .ant-modal-confirm-title {
  color: #fff;
}

.CropTool_player_confirmModal__181J3 .ant-modal-confirm-btns .ant-btn {
  color: #fff9;
  border-color: #ffffff1f;
  background-color: #ffffff1f;
  outline: none;
}

.CropTool_player_confirmModal__181J3 .ant-modal-confirm-btns .ant-btn:hover {
  color: #fff;
  background-color: #fff3;
}

.CropTool_player_confirmModal__181J3 .ant-modal-confirm-btns .ant-btn-primary {
  color: #fff;
  background-color: #1378f9;
}

.CropTool_player_confirmModal__181J3
  .ant-modal-confirm-btns
  .ant-btn-primary:hover {
  background-color: #1267d3;
}

@keyframes CropTool_player_loading__27UCP {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.CropTool_player_crop_tool__1a3Uk {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  width: 224px;
  height: 40px;
  padding: 0 8px;
  box-shadow: 0 1px 4px #16171726;
  border-radius: 4px;
  background: #333538;
  opacity: 0.8;
  color: #fff;
  font-size: 12px;
}

.CropTool_player_crop_tool_ratio__3vxxA {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.CropTool_player_crop_tool_buttons__1Mw3I {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CropTool_player_crop_tool_buttons__1Mw3I .CropTool_player_reset__3VvJ2,
.CropTool_player_crop_tool_buttons__1Mw3I .CropTool_player_cancel__3z8TK,
.CropTool_player_crop_tool_buttons__1Mw3I .CropTool_player_confirm__2lfAw {
  font-size: 14px;
}

.CropTool_player_crop_tool_buttons__1Mw3I .CropTool_player_reset__3VvJ2:hover,
.CropTool_player_crop_tool_buttons__1Mw3I .CropTool_player_cancel__3z8TK:hover,
.CropTool_player_crop_tool_buttons__1Mw3I
  .CropTool_player_confirm__2lfAw:hover {
  color: #0070cc !important;
}

.CropTool_player_crop_tool_buttons__1Mw3I
  .CropTool_player_reset__3VvJ2.CropTool_player_disabled__2cIAO {
  color: #7c7d80;
  cursor: auto;
}

.CropTool_player_crop_tool_buttons__1Mw3I
  .CropTool_player_reset__3VvJ2.CropTool_player_disabled__2cIAO:hover {
  color: #7c7d80 !important;
}

.CropTool_player_dropdown_trigger__1du6N {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 92px;
  height: 20px;
  padding: 0 10px;
  margin: 0 8px;
  background: #4e4e4e;
  border-radius: 4px;
  cursor: pointer;
}

.CropTool_player_dropdown_trigger_icon__K40Xl {
  font-size: 16px;
}

.CropTool_player_menu__ITtxP {
  width: 92px;
  padding: 5px 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  color: #fffc;
  font-size: 12px;
  list-style: none;
}

.CropTool_player_menu__ITtxP .CropTool_player_option__BNwKa {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 28px;
  padding: 10px;
  cursor: pointer;
}

.CropTool_player_menu__ITtxP
  .CropTool_player_option__BNwKa.CropTool_player_active__C1cMp,
.CropTool_player_menu__ITtxP .CropTool_player_option__BNwKa:hover {
  background: rgba(136, 136, 136, 0.8);
}

.Tooltip_player_tooltip_container__1uNdM {
  position: relative;
}

.Tooltip_player_tooltip_container__1uNdM .Tooltip_player_tooltip__3RvPN {
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%);
  padding: 4px 0;
  background-color: #323232;
  border-radius: 4px;
}

.ProportionTooltip_player_list__3fGOU {
  width: 140px;
  text-align: left;
}

.ProportionTooltip_player_list__3fGOU .ProportionTooltip_player_button__2jiU0 {
  height: 28px;
  line-height: 28px;
  padding: 0 12px;
  text-align: left;
}

.ProportionTooltip_player_custom__ULrvO {
  display: flex;
  padding-right: 0 !important;
}

.ProportionTooltip_player_custom__ULrvO button {
  padding: 1px 8px;
  background-color: transparent;
  outline: none;
  border: none;
}

.ProportionTooltip_player_customIcon__vmMl0 {
  padding: 0 8px;
  font-size: 12px;
}

.ProportionTooltip_player_customText__2CIql {
  flex: 1;
}

.ProportionTooltip_player_edit__3kdQB {
  padding: 6px 8px;
  background-color: #323232;
  display: flex;
  align-items: center;
  height: 32px;
}

.ProportionTooltip_player_edit__3kdQB .ProportionTooltip_player_gap__3JV__ {
  margin: 0 4px;
}

.ProportionTooltip_player_edit__3kdQB input {
  height: 20px;
  width: 70px;
  padding: 0 10px;
  background-color: #ffffff1a;
  border-radius: 3px;
  border: none;
  color: #fffc;
}

.ProportionTooltip_player_buttons__1j9Uz {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ProportionTooltip_player_buttons__1j9Uz .ProportionTooltip_player_reset___fG4z,
.ProportionTooltip_player_buttons__1j9Uz
  .ProportionTooltip_player_cancel__39aiJ,
.ProportionTooltip_player_buttons__1j9Uz
  .ProportionTooltip_player_confirm__1ny5o {
  margin-left: 8px;
  font-size: 12px;
}

.ProportionTooltip_player_buttons__1j9Uz
  .ProportionTooltip_player_reset___fG4z:hover,
.ProportionTooltip_player_buttons__1j9Uz
  .ProportionTooltip_player_cancel__39aiJ:hover,
.ProportionTooltip_player_buttons__1j9Uz
  .ProportionTooltip_player_confirm__1ny5o:hover {
  color: #0070cc !important;
}

.ProportionTooltip_player_buttons__1j9Uz
  .ProportionTooltip_player_reset___fG4z.ProportionTooltip_player_disabled__1A-Ls,
.ProportionTooltip_player_buttons__1j9Uz
  .ProportionTooltip_player_cancel__39aiJ.ProportionTooltip_player_disabled__1A-Ls,
.ProportionTooltip_player_buttons__1j9Uz
  .ProportionTooltip_player_confirm__1ny5o.ProportionTooltip_player_disabled__1A-Ls {
  color: #7c7d80;
  cursor: auto;
}

.ProportionTooltip_player_buttons__1j9Uz
  .ProportionTooltip_player_reset___fG4z.ProportionTooltip_player_disabled__1A-Ls:hover,
.ProportionTooltip_player_buttons__1j9Uz
  .ProportionTooltip_player_cancel__39aiJ.ProportionTooltip_player_disabled__1A-Ls:hover,
.ProportionTooltip_player_buttons__1j9Uz
  .ProportionTooltip_player_confirm__1ny5o.ProportionTooltip_player_disabled__1A-Ls:hover {
  color: #7c7d80 !important;
}

.ProgressBar_player_progressBar__13sfL {
  height: 2px;
  background-color: #fff6;
  cursor: pointer;
}

.ProgressBar_player_progressBar__13sfL .ProgressBar_player_playedArea__31zpO {
  position: relative;
  height: 100%;
  background-color: #1378f9;
}

.ProgressBar_player_progressBar__13sfL
  .ProgressBar_player_playedArea__31zpO
  .ProgressBar_player_thumb__pHpg5 {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  height: 10px;
  width: 10px;
  background-color: #fff;
  border: 1px solid #d6d6d7;
  box-shadow: 0 0 4px #00000080;
  border-radius: 50%;
}

.ResizeControl_player_preview__2Zs51 {
  width: 10%;
  height: 10%;
  background: rgba(51, 53, 56, 0.8);
  position: absolute;
  z-index: 1;
  bottom: 44px;
  right: 8px;
  box-shadow: 0 1px 4px #16171726;
  overflow: hidden;
}

.ResizeControl_player_preview__2Zs51 .ResizeControl_player_previewInner__3jUjE {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(19, 120, 249, 0.3);
  border: 1px solid rgba(19, 120, 249, 0.5);
  cursor: move;
}

.ResizeControl_player_controls__uwG9Z {
  position: absolute;
  color: #fff;
  top: 10px;
  right: 0;
  z-index: 1;
  font-size: 12px;
  background: rgba(51, 53, 56, 0.8);
  box-shadow: 0 1px 4px #16171726;
  border-radius: 2px 0 0 2px;
  padding: 3px;
}

.ResizeControl_player_controls__uwG9Z i {
  display: inline-block;
  font-size: 12px;
}

.ResizeControl_player_controls__uwG9Z .ResizeControl_player_split__3Qspp {
  display: inline-block;
  width: 1px;
  height: 10px;
  background-color: #fcfcfd40;
  margin: 0 3px;
}

.ResizeControl_player_controls__uwG9Z .ResizeControl_player_openBtn__mX9dg {
  transform: scale(-0.8);
}

.ResizeControl_player_controls__uwG9Z
  .ResizeControl_player_controlsPanel__20zCA {
  display: none;
}

.ResizeControl_player_controls__uwG9Z .ResizeControl_player_rangeNum__1xgJc {
  width: 34px;
  display: inline-block;
  margin-left: 5px;
}

.ResizeControl_player_controls__uwG9Z
  .ResizeControl_player_controlsRange__DlYTI {
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  -webkit-appearance: none;
  width: 60px;
  background: transparent;
  margin: -2px 3px 0;
  position: relative;
  cursor: pointer;
  background-color: #fcfcfd40;
  background-image: linear-gradient(#fff, #fff);
  background-size: 0% 100%;
  background-repeat: no-repeat;
  height: 2px;
}

.ResizeControl_player_controls__uwG9Z
  .ResizeControl_player_controlsRange__DlYTI:after {
  content: " ";
  width: 1px;
  height: 10px;
  display: inline-block;
  background: #fff;
  position: absolute;
  left: 14px;
  top: -4px;
}

.ResizeControl_player_controls__uwG9Z
  .ResizeControl_player_controlsRange__DlYTI::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.ResizeControl_player_controls__uwG9Z
  .ResizeControl_player_controlsRange__DlYTI::-webkit-slider-runnable-track:focus {
  width: 100%;
  height: 2px;
  cursor: pointer;
  margin-top: -2px;
  background-color: #fff;
}

.ResizeControl_player_controls__uwG9Z
  .ResizeControl_player_controlsRange__DlYTI::-webkit-slider-thumb {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 0;
}

.ResizeControl_player_controls__uwG9Z.ResizeControl_player_active__1Vb7G
  .ResizeControl_player_controlsPanel__20zCA {
  display: inline-block;
}

.ResizeControl_player_controls__uwG9Z.ResizeControl_player_active__1Vb7G
  .ResizeControl_player_openBtn__mX9dg {
  display: inline-block;
  transform: scale(0.8);
}

.views_player_confirmModal__3am6_ .ant-modal-content {
  background-color: #03060b;
  color: #fff;
  box-shadow: 0 0 4px 1px #ffffff40;
}

.views_player_confirmModal__3am6_
  .ant-modal-confirm-body
  .ant-modal-confirm-content,
.views_player_confirmModal__3am6_
  .ant-modal-confirm-body
  .ant-modal-confirm-title {
  color: #fff;
}

.views_player_confirmModal__3am6_ .ant-modal-confirm-btns .ant-btn {
  color: #fff9;
  border-color: #ffffff1f;
  background-color: #ffffff1f;
  outline: none;
}

.views_player_confirmModal__3am6_ .ant-modal-confirm-btns .ant-btn:hover {
  color: #fff;
  background-color: #fff3;
}

.views_player_confirmModal__3am6_ .ant-modal-confirm-btns .ant-btn-primary {
  color: #fff;
  background-color: #1378f9;
}

.views_player_confirmModal__3am6_
  .ant-modal-confirm-btns
  .ant-btn-primary:hover {
  background-color: #1267d3;
}

@keyframes views_player_loading__1bH-g {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: websdk-iconfont;
  src:
    url(//at.alicdn.com/t/a/font_2290528_24vtnmlw4b4.woff2?t=1704167596566)
      format("woff2"),
    url(//at.alicdn.com/t/a/font_2290528_24vtnmlw4b4.woff?t=1704167596566)
      format("woff"),
    url(//at.alicdn.com/t/a/font_2290528_24vtnmlw4b4.ttf?t=1704167596566)
      format("truetype");
}

.views_player_mainContainer__2u0q- {
  height: 100%;
  width: 100%;
  color: #fff9;
  display: flex;
  flex-direction: column;
  background: #03060b;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5715;
  position: relative;
}

.views_player_mainContainer__2u0q-.views_player_component__1dRsv {
  background: transparent;
}

.views_player_mainContainer__2u0q- i {
  font-style: normal;
  cursor: pointer;
}

.views_player_mainContainer__2u0q- i.disabled {
  color: #fff6;
  cursor: auto;
  pointer-events: auto;
}

.views_player_mainContainer__2u0q- i:not(.disabled):hover {
  color: #fff;
}

.views_player_mainContainer__2u0q- .ant-popover-hidden {
  display: none;
}

.views_player_mainContainer__2u0q- *:focus {
  outline: none;
}

.views_player_mainContainer__2u0q- * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.views_player_loading__1bH-g {
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}

.views_player_loading_font__u1PNb {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  font-size: 64px;
  animation: views_player_loading__1bH-g 0.8s linear 0s infinite;
  color: #1378f9;
}

.views_player_loading_text__qeS4G {
  top: calc(50% + 64px);
  left: calc(50% - 90px);
  text-align: center;
  color: #1378f9;
  position: absolute;
  font-size: 16px;
  width: 200px;
}

.views_player_wrap__23yP1 {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100px;
}

.views_player_body__3laEp {
  display: flex;
  height: 46%;
  min-height: 46%;
  flex: 1;
}

.views_player_bodyLeft__3DRXs {
  flex: 1 1 0;
  min-width: 430px;
}

.views_player_bodyMiddle__3s2w4 {
  position: relative;
  padding: 0 4px;
  max-width: 65%;
}

.views_player_bodyRight__22-Sz {
  position: relative;
  flex: 1 1 0;
}

.views_player_bodyRightContent__3qc1j {
  position: absolute;
  inset: 0;
}

.views_player_trackEditContainer__Z783i .views_player_editorToolBar__pZawb {
  height: 46px;
  background-color: #2a2e3a;
}

.views_player_trackEditContainer__Z783i .views_player_editorBody__zquVK {
  display: flex;
  width: 100%;
}

.views_player_trackEditContainer__Z783i .views_player_editorLeft__3Dpn9 {
  width: 180px;
}

.views_player_trackEditContainer__Z783i .views_player_editorRight__32pyF {
  height: 400px;
  width: calc(100% - 120px);
  background-color: #2a2e3a;
}

.not-support-notice .ant-message-notice-content {
  background: #333538;
  color: #fff;
}

[class^="ant-"]::-ms-clear,
[class*="ant-"]::-ms-clear,
[class^="ant-"] input::-ms-clear,
[class*="ant-"] input::-ms-clear,
[class^="ant-"] input::-ms-reveal,
[class*="ant-"] input::-ms-reveal {
  display: none;
}

[class^="ant-"],
[class*="ant-"],
[class^="ant-"] *,
[class*="ant-"] *,
[class^="ant-"] *:before,
[class*="ant-"] *:before,
[class^="ant-"] *:after,
[class*="ant-"] *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

@-ms-viewport {
  width: device-width;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  //   margin-top: 0;
  //   margin-bottom: 0.5em;
  //   color: #000000d9;
  //   font-weight: 500;
}

p {
  margin-top: 0;
  //   margin-bottom: 1em;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}

address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}

input[type="text"],
input[type="password"],
input[type="number"],
textarea {
  -webkit-appearance: none;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #40a9ff;
}

a:active {
  color: #096dd9;
}

a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}

a:focus {
  text-decoration: none;
  outline: 0;
}

a[disabled] {
  color: #00000040;
  cursor: not-allowed;
  pointer-events: none;
}

pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family:
    SFMono-Regular,
    Consolas,
    Liberation Mono,
    Menlo,
    Courier,
    monospace;
}

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}

figure {
  margin: 0 0 1em;
}

img {
  // vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: #00000073;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  padding: 0.2em;
  background-color: #feffe6;
}

::-moz-selection {
  color: #fff;
  background: #1890ff;
}

::selection {
  color: #fff;
  background: #1890ff;
}

.clearfix:before {
  display: table;
  content: "";
}

.clearfix:after {
  display: table;
  clear: both;
  content: "";
}

.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.anticon > * {
  line-height: 1;
}

.anticon svg {
  display: inline-block;
}

.anticon:before {
  display: none;
}

.anticon .anticon-icon {
  display: block;
}

.anticon[tabindex] {
  cursor: pointer;
}

.anticon-spin:before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}

.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}

.fade-enter,
.fade-appear,
.fade-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  -webkit-animation-name: antFadeIn;
  animation-name: antFadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.fade-leave.fade-leave-active {
  -webkit-animation-name: antFadeOut;
  animation-name: antFadeOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.fade-enter,
.fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.fade-leave {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes antFadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes antFadeOut {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.move-up-enter,
.move-up-appear,
.move-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.move-up-enter.move-up-enter-active,
.move-up-appear.move-up-appear-active {
  -webkit-animation-name: antMoveUpIn;
  animation-name: antMoveUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.move-up-leave.move-up-leave-active {
  -webkit-animation-name: antMoveUpOut;
  animation-name: antMoveUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.move-up-enter,
.move-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.move-down-enter,
.move-down-appear,
.move-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.move-down-enter.move-down-enter-active,
.move-down-appear.move-down-appear-active {
  -webkit-animation-name: antMoveDownIn;
  animation-name: antMoveDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.move-down-leave.move-down-leave-active {
  -webkit-animation-name: antMoveDownOut;
  animation-name: antMoveDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.move-down-enter,
.move-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.move-left-enter,
.move-left-appear,
.move-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.move-left-enter.move-left-enter-active,
.move-left-appear.move-left-appear-active {
  -webkit-animation-name: antMoveLeftIn;
  animation-name: antMoveLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.move-left-leave.move-left-leave-active {
  -webkit-animation-name: antMoveLeftOut;
  animation-name: antMoveLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.move-left-enter,
.move-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.move-right-enter,
.move-right-appear,
.move-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.move-right-enter.move-right-enter-active,
.move-right-appear.move-right-appear-active {
  -webkit-animation-name: antMoveRightIn;
  animation-name: antMoveRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.move-right-leave.move-right-leave-active {
  -webkit-animation-name: antMoveRightOut;
  animation-name: antMoveRightOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.move-right-enter,
.move-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

@-webkit-keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@-webkit-keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  to {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  to {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@-webkit-keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translate(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  to {
    -webkit-transform: translateX(0%);
    transform: translate(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translate(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  to {
    -webkit-transform: translateX(0%);
    transform: translate(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@-webkit-keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translate(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  to {
    -webkit-transform: translateX(-100%);
    transform: translate(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translate(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  to {
    -webkit-transform: translateX(-100%);
    transform: translate(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@-webkit-keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
    transform: translate(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  to {
    -webkit-transform: translateX(0%);
    transform: translate(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
    transform: translate(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  to {
    -webkit-transform: translateX(0%);
    transform: translate(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@-webkit-keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translate(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  to {
    -webkit-transform: translateX(100%);
    transform: translate(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
    transform: translate(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  to {
    -webkit-transform: translateX(100%);
    transform: translate(100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@-webkit-keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@-webkit-keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  to {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 0;
  }
}

@-webkit-keyframes loadingCircle {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loadingCircle {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

[ant-click-animating="true"],
[ant-click-animating-without-extra-node="true"] {
  position: relative;
}

html {
  --antd-wave-shadow-color: #1890ff;
  --scroll-bar: 0;
}

[ant-click-animating-without-extra-node="true"]:after,
.ant-click-animating-node {
  position: absolute;
  inset: 0;
  display: block;
  border-radius: inherit;
  -webkit-box-shadow: 0 0 0 0 #1890ff;
  box-shadow: 0 0 #1890ff;
  -webkit-box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  -webkit-animation:
    fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1),
    waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation:
    fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1),
    waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  content: "";
  pointer-events: none;
}

@-webkit-keyframes waveEffect {
  to {
    -webkit-box-shadow: 0 0 0 #1890ff;
    box-shadow: 0 0 #1890ff;
    -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}

@keyframes waveEffect {
  to {
    -webkit-box-shadow: 0 0 0 #1890ff;
    box-shadow: 0 0 #1890ff;
    -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}

@-webkit-keyframes fadeEffect {
  to {
    opacity: 0;
  }
}

@keyframes fadeEffect {
  to {
    opacity: 0;
  }
}

.slide-up-enter,
.slide-up-appear,
.slide-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.slide-up-enter.slide-up-enter-active,
.slide-up-appear.slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.slide-up-leave.slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.slide-up-enter,
.slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.slide-down-enter,
.slide-down-appear,
.slide-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.slide-down-enter.slide-down-enter-active,
.slide-down-appear.slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.slide-down-leave.slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.slide-down-enter,
.slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.slide-left-enter,
.slide-left-appear,
.slide-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.slide-left-enter.slide-left-enter-active,
.slide-left-appear.slide-left-appear-active {
  -webkit-animation-name: antSlideLeftIn;
  animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.slide-left-leave.slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
  animation-name: antSlideLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.slide-left-enter,
.slide-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.slide-right-enter,
.slide-right-appear,
.slide-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.slide-right-enter.slide-right-enter-active,
.slide-right-appear.slide-right-appear-active {
  -webkit-animation-name: antSlideRightIn;
  animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.slide-right-leave.slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
  animation-name: antSlideRightOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.slide-right-enter,
.slide-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@-webkit-keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }

  to {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }

  to {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@-webkit-keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }

  to {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

@keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }

  to {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

@-webkit-keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }

  to {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }

  to {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@-webkit-keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }

  to {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }
}

@keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }

  to {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }
}

@-webkit-keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@-webkit-keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }

  to {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

@keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }

  to {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

@-webkit-keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

@-webkit-keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  to {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }
}

@keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }

  to {
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 0;
  }
}

.zoom-enter,
.zoom-appear,
.zoom-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-enter.zoom-enter-active,
.zoom-appear.zoom-appear-active {
  -webkit-animation-name: antZoomIn;
  animation-name: antZoomIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.zoom-leave.zoom-leave-active {
  -webkit-animation-name: antZoomOut;
  animation-name: antZoomOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.zoom-enter,
.zoom-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-enter-prepare,
.zoom-appear-prepare {
  -webkit-transform: none;
  transform: none;
}

.zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-big-enter,
.zoom-big-appear,
.zoom-big-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-big-enter.zoom-big-enter-active,
.zoom-big-appear.zoom-big-appear-active {
  -webkit-animation-name: antZoomBigIn;
  animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.zoom-big-leave.zoom-big-leave-active {
  -webkit-animation-name: antZoomBigOut;
  animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.zoom-big-enter,
.zoom-big-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-big-enter-prepare,
.zoom-big-appear-prepare {
  -webkit-transform: none;
  transform: none;
}

.zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-big-fast-enter,
.zoom-big-fast-appear,
.zoom-big-fast-leave {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-big-fast-enter.zoom-big-fast-enter-active,
.zoom-big-fast-appear.zoom-big-fast-appear-active {
  -webkit-animation-name: antZoomBigIn;
  animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.zoom-big-fast-leave.zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
  animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.zoom-big-fast-enter,
.zoom-big-fast-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-big-fast-enter-prepare,
.zoom-big-fast-appear-prepare {
  -webkit-transform: none;
  transform: none;
}

.zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-up-enter,
.zoom-up-appear,
.zoom-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-up-enter.zoom-up-enter-active,
.zoom-up-appear.zoom-up-appear-active {
  -webkit-animation-name: antZoomUpIn;
  animation-name: antZoomUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.zoom-up-leave.zoom-up-leave-active {
  -webkit-animation-name: antZoomUpOut;
  animation-name: antZoomUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.zoom-up-enter,
.zoom-up-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-up-enter-prepare,
.zoom-up-appear-prepare {
  -webkit-transform: none;
  transform: none;
}

.zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-down-enter,
.zoom-down-appear,
.zoom-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-down-enter.zoom-down-enter-active,
.zoom-down-appear.zoom-down-appear-active {
  -webkit-animation-name: antZoomDownIn;
  animation-name: antZoomDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.zoom-down-leave.zoom-down-leave-active {
  -webkit-animation-name: antZoomDownOut;
  animation-name: antZoomDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.zoom-down-enter,
.zoom-down-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-down-enter-prepare,
.zoom-down-appear-prepare {
  -webkit-transform: none;
  transform: none;
}

.zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-left-enter,
.zoom-left-appear,
.zoom-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-left-enter.zoom-left-enter-active,
.zoom-left-appear.zoom-left-appear-active {
  -webkit-animation-name: antZoomLeftIn;
  animation-name: antZoomLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.zoom-left-leave.zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftOut;
  animation-name: antZoomLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.zoom-left-enter,
.zoom-left-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-left-enter-prepare,
.zoom-left-appear-prepare {
  -webkit-transform: none;
  transform: none;
}

.zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.zoom-right-enter,
.zoom-right-appear,
.zoom-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-right-enter.zoom-right-enter-active,
.zoom-right-appear.zoom-right-appear-active {
  -webkit-animation-name: antZoomRightIn;
  animation-name: antZoomRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.zoom-right-leave.zoom-right-leave-active {
  -webkit-animation-name: antZoomRightOut;
  animation-name: antZoomRightOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.zoom-right-enter,
.zoom-right-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-right-enter-prepare,
.zoom-right-appear-prepare {
  -webkit-transform: none;
  transform: none;
}

.zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

@-webkit-keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }
}

@keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }
}

@-webkit-keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }
}

@keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }
}

@-webkit-keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
}

@keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
}

@-webkit-keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }

  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
}

@keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }

  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
  }
}

@-webkit-keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }
}

@keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }
}

@-webkit-keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }

  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0;
  }
}

@keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }

  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    opacity: 0;
  }
}

@-webkit-keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
}

@keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
}

@-webkit-keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }

  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 0;
  }
}

@keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }

  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 0;
  }
}

@-webkit-keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
}

@keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
}

@-webkit-keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
}

@keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }

  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 0;
  }
}

.ant-motion-collapse-legacy {
  overflow: hidden;
}

.ant-motion-collapse-legacy-active {
  -webkit-transition:
    height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition:
    height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-motion-collapse {
  overflow: hidden;
  -webkit-transition:
    height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  transition:
    height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-message {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}

.ant-message-notice {
  padding: 8px;
  text-align: center;
}

.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 2px;
  -webkit-box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow:
    0 3px 6px -4px #0000001f,
    0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;
  pointer-events: all;
}

.ant-message-success .anticon {
  color: #52c41a;
}

.ant-message-error .anticon {
  color: #ff4d4f;
}

.ant-message-warning .anticon {
  color: #faad14;
}

.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #1890ff;
}

.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}

.ant-message-notice.move-up-leave.move-up-leave-active {
  -webkit-animation-name: MessageMoveOut;
  animation-name: MessageMoveOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }

  to {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }

  to {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}

.ant-message-rtl,
.ant-message-rtl span {
  direction: rtl;
}

.ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px;
}

.ant-tooltip {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: absolute;
  z-index: 1070;
  display: block;
  max-width: 250px;
  visibility: visible;
}

.ant-tooltip-hidden {
  display: none;
}

.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}

.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 8px;
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}

.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 8px;
}

.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: #000000bf;
  border-radius: 2px;
  -webkit-box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow:
    0 3px 6px -4px #0000001f,
    0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;
}

.ant-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}

.ant-tooltip-arrow-content {
  position: absolute;
  inset: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: #000000bf;
  content: "";
  pointer-events: auto;
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -5.07106781px;
}

.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: 3px 3px 7px #00000012;
  -webkit-transform: translateY(-6.53553391px) rotate(45deg);
  transform: translateY(-6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translate(-50%);
}

.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}

.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}

.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: -5.07106781px;
}

.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
  -webkit-box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: -3px 3px 7px #00000012;
  -webkit-transform: translateX(6.53553391px) rotate(45deg);
  transform: translate(6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}

.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}

.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: -5.07106781px;
}

.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
  -webkit-box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: 3px -3px 7px #00000012;
  -webkit-transform: translateX(-6.53553391px) rotate(45deg);
  transform: translate(-6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}

.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -5.07106781px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  -webkit-box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: -3px -3px 7px #00000012;
  -webkit-transform: translateY(6.53553391px) rotate(45deg);
  transform: translateY(6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translate(-50%);
}

.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}

.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}

.ant-tooltip-pink .ant-tooltip-inner,
.ant-tooltip-pink .ant-tooltip-arrow-content,
.ant-tooltip-magenta .ant-tooltip-inner,
.ant-tooltip-magenta .ant-tooltip-arrow-content {
  background-color: #eb2f96;
}

.ant-tooltip-red .ant-tooltip-inner,
.ant-tooltip-red .ant-tooltip-arrow-content {
  background-color: #f5222d;
}

.ant-tooltip-volcano .ant-tooltip-inner,
.ant-tooltip-volcano .ant-tooltip-arrow-content {
  background-color: #fa541c;
}

.ant-tooltip-orange .ant-tooltip-inner,
.ant-tooltip-orange .ant-tooltip-arrow-content {
  background-color: #fa8c16;
}

.ant-tooltip-yellow .ant-tooltip-inner,
.ant-tooltip-yellow .ant-tooltip-arrow-content {
  background-color: #fadb14;
}

.ant-tooltip-gold .ant-tooltip-inner,
.ant-tooltip-gold .ant-tooltip-arrow-content {
  background-color: #faad14;
}

.ant-tooltip-cyan .ant-tooltip-inner,
.ant-tooltip-cyan .ant-tooltip-arrow-content {
  background-color: #13c2c2;
}

.ant-tooltip-lime .ant-tooltip-inner,
.ant-tooltip-lime .ant-tooltip-arrow-content {
  background-color: #a0d911;
}

.ant-tooltip-green .ant-tooltip-inner,
.ant-tooltip-green .ant-tooltip-arrow-content {
  background-color: #52c41a;
}

.ant-tooltip-blue .ant-tooltip-inner,
.ant-tooltip-blue .ant-tooltip-arrow-content {
  background-color: #1890ff;
}

.ant-tooltip-geekblue .ant-tooltip-inner,
.ant-tooltip-geekblue .ant-tooltip-arrow-content {
  background-color: #2f54eb;
}

.ant-tooltip-purple .ant-tooltip-inner,
.ant-tooltip-purple .ant-tooltip-arrow-content {
  background-color: #722ed1;
}

.ant-tooltip-rtl {
  direction: rtl;
}

.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #ff4d4f;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #ff4d4f;
}

.ant-dropdown {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}

.ant-dropdown:before {
  position: absolute;
  inset: -4px 0 -4px -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: " ";
}

.ant-dropdown-wrap {
  position: relative;
}

.ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 10px;
}

.ant-dropdown-wrap .anticon-down:before {
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition:
    transform 0.2s,
    -webkit-transform 0.2s;
}

.ant-dropdown-wrap-open .anticon-down:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
  display: none;
}

.ant-dropdown-show-arrow.ant-dropdown-placement-topCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 10px;
}

.ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 10px;
}

.ant-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  -webkit-box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  box-shadow: 3px 3px 7px #00000012;
}

.ant-dropdown-placement-topCenter > .ant-dropdown-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translate(-50%) rotate(45deg);
}

.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px;
}

.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px;
}

.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  -webkit-box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  box-shadow: -2px -2px 5px #0000000f;
}

.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translate(-50%) rotate(45deg);
}

.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px;
}

.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px;
}

.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  -webkit-box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow:
    0 3px 6px -4px #0000001f,
    0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;
}

.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: #00000073;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}

.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: #000000d9;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-menu-item > a > .anticon:first-child,
.ant-dropdown-menu-submenu-title > a > .anticon:first-child,
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
  vertical-align: -0.1em;
}

.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: #000000d9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-submenu-title > a:hover {
  color: #000000d9;
}

.ant-dropdown-menu-item > .anticon + span > a,
.ant-dropdown-menu-submenu-title > .anticon + span > a {
  color: #000000d9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.ant-dropdown-menu-item > .anticon + span > a:hover,
.ant-dropdown-menu-submenu-title > .anticon + span > a:hover {
  color: #000000d9;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #1890ff;
  background-color: #e6f7ff;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #f5f5f5;
}

.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: #00000040;
  cursor: not-allowed;
}

.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: #00000040;
  background-color: #fff;
  cursor: not-allowed;
}

.ant-dropdown-menu-item-disabled > a,
.ant-dropdown-menu-submenu-title-disabled > a {
  position: relative;
  color: #00000040;
  pointer-events: none;
}

.ant-dropdown-menu-item-disabled > a:after,
.ant-dropdown-menu-submenu-title-disabled > a:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
  content: "";
}

.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}

.ant-dropdown-menu-item
  .ant-dropdown-menu-submenu-expand-icon
  .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title
  .ant-dropdown-menu-submenu-expand-icon
  .ant-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: #00000073;
  font-size: 10px;
  font-style: normal;
}

.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}

.ant-dropdown-menu-submenu-title {
  padding-right: 24px;
}

.ant-dropdown-menu-submenu-vertical {
  position: relative;
}

.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled
  .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled
  .ant-dropdown-menu-submenu-title
  .ant-dropdown-menu-submenu-arrow-icon {
  color: #00000040;
  background-color: #fff;
  cursor: not-allowed;
}

.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #1890ff;
}

.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
}

.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
}

.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
}

.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
}

.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}

.ant-dropdown-button {
  white-space: nowrap;
}

.ant-dropdown-button.ant-btn-group
  > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}

.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
  color: #ffffffa6;
}

.ant-dropdown-menu-dark
  .ant-dropdown-menu-item
  .ant-dropdown-menu-submenu-arrow:after,
.ant-dropdown-menu-dark
  .ant-dropdown-menu-submenu-title
  .ant-dropdown-menu-submenu-arrow:after,
.ant-dropdown-menu-dark
  .ant-dropdown-menu-item
  > a
  .ant-dropdown-menu-submenu-arrow:after,
.ant-dropdown-menu-dark
  .ant-dropdown-menu-item
  > .anticon
  + span
  > a
  .ant-dropdown-menu-submenu-arrow:after {
  color: #ffffffa6;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: transparent;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #1890ff;
}

.ant-dropdown-rtl {
  direction: rtl;
}

.ant-dropdown-rtl.ant-dropdown:before {
  right: -7px;
  left: 0;
}

.ant-dropdown-menu.ant-dropdown-menu-rtl,
.ant-dropdown-rtl .ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}

.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li,
.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right;
}

.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-rtl
  .ant-dropdown-menu-submenu-title
  > span
  > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px;
}

.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-rtl
  .ant-dropdown-menu-submenu-title
  .ant-dropdown-menu-submenu-arrow {
  right: auto;
  left: 8px;
}

.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl
  .ant-dropdown-menu-submenu-title
  .ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px;
}

.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}

.ant-modal {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 0 24px;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
}

.ant-modal.zoom-enter,
.ant-modal.zoom-appear {
  -webkit-transform: none;
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-modal-mask {
  position: fixed;
  inset: 0;
  z-index: 1000;
  height: 100%;
  background-color: #00000073;
}

.ant-modal-mask-hidden {
  display: none;
}

.ant-modal-wrap {
  position: fixed;
  inset: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

.ant-modal-wrap {
  z-index: 1000;
}

.ant-modal-title {
  margin: 0;
  color: #000000d9;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  -webkit-box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow:
    0 3px 6px -4px #0000001f,
    0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;
  pointer-events: auto;
}

.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: #00000073;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}

.ant-modal-close:focus,
.ant-modal-close:hover {
  color: #000000bf;
  text-decoration: none;
}

.ant-modal-header {
  padding: 16px 24px;
  color: #000000d9;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}

.ant-modal-footer button + button {
  margin-bottom: 0;
  margin-left: 8px;
}

.ant-modal-open {
  overflow: hidden;
}

.ant-modal-centered {
  text-align: center;
}

.ant-modal-centered:before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: "";
}

.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }

  .ant-modal-centered .ant-modal {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}

.ant-modal-confirm .ant-modal-header {
  display: none;
}

.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}

.ant-modal-confirm-body-wrapper:before {
  display: table;
  content: "";
}

.ant-modal-confirm-body-wrapper:after {
  display: table;
  clear: both;
  content: "";
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: #000000d9;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: #000000d9;
  font-size: 14px;
}

.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}

.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-left: 38px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}

.ant-modal-confirm .ant-modal-confirm-btns button + button {
  margin-bottom: 0;
  margin-left: 8px;
}

.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #ff4d4f;
}

.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}

.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #1890ff;
}

.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}

.ant-modal-wrap-rtl {
  direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-close {
  right: initial;
  left: 0;
}

.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}

.ant-modal-wrap-rtl .ant-modal-footer button + button {
  margin-right: 8px;
  margin-left: 0;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}

.ant-modal-wrap-rtl
  .ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  float: left;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns button + button {
  margin-right: 8px;
  margin-left: 0;
}

.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}
